export default [
  {
    key: 'playerId',
    label: 'field.player',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'jackpotSetting',
    selectionKey: 'id',
    selectionLabel: 'username',
    cols: 12,
  },
  {
    key: 'amount',
    label: 'field.amount',
    type: 'currency',
    disabled: true,
    cols: 12,
  },
]
