<template>
  <div>
    <b-row class="m-0 jackpot">
      <b-col class="p-0 prize first-prize" cols="12" lg="4">
        <div class="cursor-pointer" @click="randomWinner(1)">
          <div class="bg"></div>
          <div class="medal"></div>
          <div class="text">
            <h6 class="amount">{{ goldJackpot | currency(116, 0) }}</h6>
            <h5 class="type">{{ $t("general.firstPrize") }}</h5>
          </div>
        </div>
      </b-col>
      <b-col class="p-0 prize second-prize" cols="12" lg="4">
        <div class="cursor-pointer" @click="randomWinner(2)">
          <div class="bg"></div>
          <div class="medal"></div>
          <div class="text">
            <h6 class="amount">{{ silverJackpot | currency(116, 0) }}</h6>
            <h5 class="type">{{ $t("general.secondPrize") }}</h5>
          </div>
        </div>
      </b-col>
      <b-col class="p-0 prize third-prize" cols="12" lg="4">
        <div class="cursor-pointer" @click="randomWinner(3)">
          <div class="bg"></div>
          <div class="medal"></div>
          <div class="text">
            <h6 class="amount">{{ bronzeJackpot | currency(116, 0) }}</h6>
            <h5 class="type">{{ $t("general.thirdPrize") }}</h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <random-jackpot-winner
      ref="randomJackpotWinner"
      @success="success"
    ></random-jackpot-winner>
  </div>
</template>

<script>
import db from "@/libs/firestore-db";
import { BRow, BCol } from "bootstrap-vue";
import RandomJackpotWinner from "./RandomJackpotWinner";

export default {
  components: {
    BRow,
    BCol,
    RandomJackpotWinner,
  },
  data() {
    return {
      goldJackpot: 0,
      silverJackpot: 0,
      bronzeJackpot: 0,
    };
  },
  watch: {
    goldJackpot(value) {
      this.$refs.randomJackpotWinner.changeAmount(value, 1);
    },
    silverJackpot(value) {
      this.$refs.randomJackpotWinner.changeAmount(value, 2);
    },
    bronzeJackpot(value) {
      this.$refs.randomJackpotWinner.changeAmount(value, 3);
    },
  },
  created() {
    this.listenJackpot();
  },
  methods: {
    listenJackpot() {
      db.collection("fight_data")
        .doc(`jackpot`)
        .onSnapshot((response) => {
          this.goldJackpot = Number(response.get("gold_jackpot_116") || 0);
          this.silverJackpot = Number(response.get("silver_jackpot_116") || 0);
          this.bronzeJackpot = Number(response.get("bronze_jackpot_116") || 0);
        });
    },
    randomWinner(prize) {
      let amount = 0;
      switch (prize) {
        case 1:
          amount = this.goldJackpot;
          break;
        case 2:
          amount = this.silverJackpot;
          break;
        case 3:
          amount = this.bronzeJackpot;
          break;
      }
      this.$refs.randomJackpotWinner.changeData({
        type: prize,
        amount: amount,
        playerId: null,
      });
      this.$refs.randomJackpotWinner.show();
    },
    success() {
      this.$emit("success");
    },
  },
};
</script>