<template>
  <b-modal
    id="modal-random-jackpot-winner"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    :title="modalTitle"
    @ok="handleOk"
  >
    <validation-observer ref="randomJackpotWinnerForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="randomJackpotWinnerForm"
        :form="$refs.randomJackpotWinnerForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data">
          <template #playerId="item">
            <b-form-group :label-for="item.field.key">
              <validation-provider
                #default="{ errors }"
                :vid="item.field.key"
                :name="$t(item.field.label)"
                :rules="item.field.rules"
              >
                <label>
                  {{ $t(item.field.label) }}
                </label>
                <n-async-single-select
                  v-model="data[item.field.key]"
                  :name="item.field.key"
                  :init-options="data[item.field.key]"
                  :repository="item.field.repository"
                  :selection-key="item.field.selectionKey"
                  :selection-label="item.field.selectionLabel"
                  @optionChange="changeOption"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                ></n-async-single-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>
        </n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import FormInput from "./randomJackportWinnerFormInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

const JackpotSettingRepository = Repository.get("jackpotSetting");

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
  },
  watch: {
    "data.type": function (value) {
      this.modalTitle = this.$t("prize.randomWinner", {
        value: this.$t(`prize.${value}`),
      });
    },
  },
  data() {
    return {
      loading: false,
      data: {
        type: "",
        amount: 0,
        playerId: "",
        fightMatchId: "",
      },
      modalTitle: "",
    };
  },
  methods: {
    changeOption(value) {
      if (value) {
        this.data.fightMatchId = value?.fightMatchId;
      }
    },
    show() {
      this.$bvModal.show("modal-random-jackpot-winner");
    },
    hide() {
      this.$bvModal.hide("modal-random-jackpot-winner");
    },
    changeAmount(amount, type) {
      if (type == this.data.type) {
        this.data.amount = amount;
      }
    },
    changeData(data) {
      this.data = {
        ...data,
      };
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.randomJackpotWinnerForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      JackpotSettingRepository.pickWinner(this.data)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.randomJackpotWinnerForm.setErrors(
              error.response?.data?.errors
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
