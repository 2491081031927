export default [
  {
    key: "minGold",
    label: "field.minimumFirstPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "maxGold",
    label: "field.maximumFirstPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "increaseGold",
    label: "field.firstPrizeIncreament",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "timeIncreaseGold",
    label: "field.timeIncreaseGold",
    rules: "required|min_value:0",
    type: "text",
    cols: 3,
  },
  {
    key: "minSilver",
    label: "field.minimumSecondPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "maxSilver",
    label: "field.maximumSecondPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "increaseSilver",
    label: "field.secondPrizeIncreament",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "timeIncreaseSilver",
    label: "field.timeIncreaseSilver",
    rules: "required|min_value:0",
    type: "text",
    cols: 3,
  },
  {
    key: "minBronze",
    label: "field.minimumThirdPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "maxBronze",
    label: "field.maximumThirdPrize",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "increaseBronze",
    label: "field.thirdPrizeIncreament",
    rules: "required|decimal:2|min_value:0",
    type: "currency",
    cols: 3,
  },
  {
    key: "timeIncreaseBronze",
    label: "field.timeIncreaseBronze",
    rules: "required|min_value:0",
    type: "text",
    cols: 3,
  },
  // {
  //   key: "timeNextJackpot",
  //   label: "field.timeNextJackpot",
  //   rules: "required|integer|min_value:300",
  //   type: "currency",
  // },
  {
    key: "timeNextJackpotGold",
    label: "field.timeNextJackpotGold",
    rules: "required|integer|min_value:300",
    type: "currency",
    cols: 4,
  },
  {
    key: "timeNextJackpotSilver",
    label: "field.timeNextJackpotSilver",
    rules: "required|integer|min_value:300",
    type: "currency",
    cols: 4,
  },
  {
    key: "timeNextJackpotBronze",
    label: "field.timeNextJackpotBronze",
    rules: "required|integer|min_value:300",
    type: "currency",
    cols: 4,
  },
  {
    key: "isAutoDropGold",
    label: "field.autoDropJackpotGold",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "isAutoDropSilver",
    label: "field.autoDropJackpotSilver",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "isAutoDropBronze",
    label: "field.autoDropJackpotBronze",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  // {
  //   key: "isAutoDrop",
  //   label: "field.autoDropJackpot",
  //   rules: "",
  //   type: "checkbox",
  //   cols: "auto",
  // },
];
